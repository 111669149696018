import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Animation from '../images/ani-graph.inline.svg';

const ComplaintsPage = () => {
    return (
        <Layout>
            <Seo
                title="Complaints Handling Policy - Lifespan Consultants"
                description="Complaints Handling Policy"
            />
            <div className="app-title">
                <div className="title-txt">
                    <h1
                        data-sal="slide-up"
                        data-sal-duration="500"
                        data-sal-delay="400"
                        data-sal-easing="easeOutCubic"
                    >
                        Complaints Handling Policy
                    </h1>
                </div>
                <div className="app-ani">
                    <Animation />
                </div>
            </div>
            <div className="content">
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-12">
                            <div className="privacyPolicy">
                                <h2>Incidents and Breaches</h2>
                                <h3>
                                    <strong>Purpose</strong>
                                </h3>
                                <p>
                                    GBA Kotkis Pty Ltd is committed to
                                    conducting its business in accordance with
                                    all applicable laws and regulations. GBA
                                    Kotkis Pty Ltd has an obligation to comply
                                    with its general obligations under section
                                    912A(1) of the Corporations Act 2001;
                                    including facilitating the timely
                                    identification, escalation and rectification
                                    of complaints. This means that the
                                    management of the actual and potential
                                    impact, including the potential to minimise
                                    complaints from occurring in the future, can
                                    be efficiently managed. This policy has been
                                    developed with reference to ASIC Regulatory
                                    Guide 271 Internal Dispute Resolution.
                                </p>
                                <h2>How to lodge a complaint</h2>
                                <p>
                                    A complaint can be submitted via several
                                    sources depending on the nature of the
                                    complaint. Complaints can be received:
                                </p>
                                <ul>
                                    <li>via email</li>
                                    <li>via phone</li>
                                    <li>in writing</li>
                                    <li>in person</li>
                                </ul>
                                <p>
                                    Your Adviser is best placed to receive your
                                    complaint initially. You can also reach out
                                    to our Compliance Team who handles all
                                    complaints. The Compliance Team can be
                                    reached by:
                                </p>
                                <p>
                                    <strong>Email at:</strong>
                                    <br />
                                    gavin@lifespanwa.com.au
                                </p>
                                <p>
                                    <strong>Write to:</strong>
                                    <br />
                                    Gavin Kotkis
                                    <br />
                                    Lifespan Consultants
                                    <br />
                                    660C Newcastle St, 
                                    <br />
                                    Leederville WA 6007
                                </p>
                                <h2>
                                    Assistance required for lodging complaints
                                </h2>
                                <p>
                                    If you require assistance in lodging your
                                    complaint, please reach out to your Adviser
                                    or the compliance team.
                                </p>
                                <h2>Process for handling complaints</h2>
                                <p>
                                    GBA Kotkis Pty Ltd’s internal process for
                                    handling complaints aims to ensure that
                                    complaints are resolved in a timely, fair
                                    and consistent manner. Our internal process
                                    is:
                                </p>
                                <ul>
                                    <li>
                                        Within one business day, we acknowledge
                                        that we have received the complaint. We
                                        aim to resolve the complaint
                                        immediately, if possible.
                                    </li>
                                    <li>
                                        Where we cannot resolve the complaint
                                        immediately, our Compliance Team will
                                        investigate the complaint and conclude
                                        on an appropriate resolution.
                                    </li>
                                    <li>
                                        The complainant will be informed of the
                                        resolution within 30 days. If we are
                                        unable to conclude on the resolution
                                        within 30 days, we will provide the
                                        complainant with the details to the
                                        Australian Financial Complaints
                                        Authority (AFCA).
                                    </li>
                                </ul>
                                <h2>Response timeframes</h2>
                                <p>
                                    We are required to provide a final response
                                    to a complaint within 30 calendar days of
                                    the complaint being lodged. There may,
                                    however, be factors outside of our control,
                                    that may extend this timeframe.
                                </p>
                                <h2>
                                    The Australian Financial Complaints
                                    Authority
                                </h2>
                                <p>
                                    If a complainant is not satisfied with GBA
                                    Kotkis Pty Ltd’s response to their
                                    complaint, they may be entitled to refer
                                    this matter to AFCA. AFCA is an independent
                                    external complaints resolution body. GBA
                                    Kotkis Pty Ltd’s AFCA membership number is
                                    70721. The contact details for AFCA are:
                                </p>
                                <p>
                                    Mail: GPO Box 3<br />
                                    Melbourne VIC 3001
                                    <br />
                                    <br />
                                    Telephone: 1800 931 678
                                    <br />
                                    Website: www.afca.org.au
                                    <br />
                                    Email: info@afca.org.au
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ComplaintsPage;
